import { meta } from 'lib/dom.js'

if (!window.smartlook) {
	window.smartlook = function() {
		window.smartlook.api.push(arguments)
	}

	window.smartlook.api = []
}

window.smartlook('init', meta('tracking.smartlook'))
